import request from '../utils/request';

//获取验证码
export function getKaptcha(param) {
    return request({
        url: `/v1/kaptcha?uuid=${param}`,
        method: 'get',
    })
}
// 用户登录
export function login(data) {
    return request({
        url: '/v1/login',
        method: 'post',
        data
    })
}
//退出登录
export function layoutLogin(data) {
    return request({
        url: `/v1/user/logout`,
        method: 'post',
        data
    })
}
//注册
export function register(data) {
    return request({
        url: '/v1/register',
        method: 'post',
        data
    })
}
//发短信
export function sendText(data) {
    return request({
        url: '/v1/sms/sendVerifyCode',
        method: 'post',
        data
    })
}
//重置密码
export function resetPassword(data) {
    return request({
        url: '/v1/findPwd',
        method: 'post',
        data
    })
}

export const textualInfoByCode = (params) => {
    return request({
      url: `https://dianshang-api.cpolar.cn/v1/textual/appList`,
      method: 'get',
      params
    })
  }
