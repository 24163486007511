import request from '../utils/request';

//获取首页数据
export function getIndex() {
    return request({
        url: `/v1/index`,
        method: 'get',
    })
}
//获取货运需求
export function getInted(data) {
    return request({
        url: `/v1/index/getInted`,
        method: 'post',
        data
    })
}
//获取我的订单
export function getOrder(data) {
    return request({
        url: `/v1/orderRest/selectOrder`,
        method: 'post',
        data
    })
}
//获取我的订单详情
export function getOrderDetail(orderNum,carCode) {
    let url = `/v1/orderRest/orderDetail?orderNum=${orderNum}`;
    if(carCode){
        url += `&carCode=${carCode}`;
    }
    return request({
        url: url,
        method: 'get',
    })
}
//获取账号信息
export function getUserInfo() {
    return request({
        url: `/v1/user/center`,
        method: 'get',
    })
}
//获取意向地列表
export function getDestinationlist(userId) {
    return request({
        url: `/v1/destination/list/${userId}`,
        method: 'get',
    })
}
//添加意向地
export function addDestination(data) {
    return request({
        url: `/v1/destination/create`,
        method: 'post',
        data
    })
}
//删除意向地
export function deleteDestination(destinationId) {
    return request({
        url: `/v1/destination/delete/${destinationId}`,
        method: 'get',
    })
}
//修改头像
export function updateHeadImage(data) {
    return request({
        url: `/v1/user/updateHeadImage`,
        method: 'post',
        data
    })
}
//修改头像
export function updatePassword(data) {
    return request({
        url: `/v1/user/password`,
        method: 'post',
        data
    })
}
//获取消息列表
export function getMessageList(data) {
    return request({
        url: `/v1/msg/message/findPageForPc`,
        method: 'post',
        data
    })
}
//获取车辆列表
export function getCarList(data) {
    return request({
        url: `/v1/car/getList`,
        method: 'post',
        data
    })
}
//获取车辆详情
export function getCarDetail(carId) {
    return request({
        url: `/v1/car/get/${carId}`,
        method: 'get',
    })
}
//编辑车辆信息
export function editCar(data) {
    return request({
        url: `/v1/car/update`,
        method: 'post',
        data
    })
}
//认证车辆信息
export function authCar(data) {
    return request({
        url: `/v1/car/submit`,
        method: 'post',
        data
    })
}
//删除车辆信息
export function delCar(carId) {
    return request({
        url: `/v1/car/delete/${carId}`,
        method: 'get',
    })
}
//添加车辆信息
export function addCar(data) {
    return request({
        url: `/v1/car/add`,
        method: 'post',
        data
    })
}
//获取我的报价
export function getMyBidList(data) {
    return request({
        url: `/v1/bid/myBidList?pageNumber=${data.pageNumber}&pageSize=${data.pageSize}`,
        method: 'get',
    })
}
//获取我的运单列表
export function getWayBillOrder(data) {
    return request({
        url: `/v1/orderRest/selectOrderDetails`,
        method: 'post',
        data
    })
}
//开始启运or结束运单
export function startOrOverOrderDetail(data) {
    return request({
        url: `/v1/orderRest/startOrOverOrderDetail`,
        method: 'post',
        data
    })
}
//评论
export function addComment(data) {
    return request({
        url: `/v1/orderComment/add`,
        method: 'post',
        data
    })
}
//银行列表
export function bankList(userId) {
    return request({
        url: `/v1/bank/list/${userId}`,
        method: 'get',
    })
}
//添加银行
export function addBankCard(data) {
    return request({
        url: `/v1/bank/add`,
        method: 'post',
        data
    })
}
//银行卡信息
export function getBank(id) {
    return request({
        url: `/v1/bank/get/${id}`,
        method: 'get',
    })
}
//删除银行卡
export function delBank(id) {
    return request({
        url: `/v1/bank/delete/${id}`,
        method: 'get',
    })
}
//编辑银行卡
export function editBankCard(data) {
    return request({
        url: `/v1/bank/update`,
        method: 'post',
        data
    })
}
//账户中心
export function myAccount() {
    return request({
        url: `/v1/payment/myAccount`,
        method: 'get',
    })
}
//获取财务明细
export function findBillPage(data) {
    return request({
        url: `/v1/payment/findBillPage`,
        method: 'post',
        data
    })
}
//获取财务收入，支出数量
export function inAndOutCount(data) {
    return request({
        url: `/v1/payment/inAndOutCount`,
        method: 'post',
        data
    })
}
//提现申请
export function applyWithdrawals(data) {
    return request({
        url: `/v1/payment/applyWithdrawals`,
        method: 'post',
        data
    })
}
//获取我要报价的信息
export function getMyBid(tenderId) {
    return request({
        url: `/v1/demand/findById/${tenderId}`,
        method: 'get',
    })
}
//新增报价
export function addBid(data) {
    return request({
        url: `/v1/bid/add`,
        method: 'post',
        data
    })
}
//定向模式新增报价
export function addToOrder(data) {
    return request({
        url: `/v1/bid/addToOrder`,
        method: 'post',
        data
    })
}
//申请物料认证
export function logisticsAuth(data) {
    return request({
        url: `/v1/user/apply/logistics`,
        method: 'post',
        hideMessage:true,
        data
    })
}
//获取文章详情
export function getArticleDetail(param) {
    return request({
        url: `/v1/news/detail/${param}`,
        method: 'get',
    })
}
//获取行业动态
export function getIndustryList() {
    return request({
        url: `/v1/news/list-industry`,
        method: 'get',
    })
}
//获取公司新闻
export function getNewsList() {
    return request({
        url: `/v1/news/list-news`,
        method: 'get',
    })
}
