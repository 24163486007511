import { createApp } from 'vue';
import App from './App.vue';
import router from "@/router";
import store from "@/store";

router.beforeEach((to, from, next) => {
    if (to.path=='/login') {
        //这里判断用户是否登录，验证本地存储是否有token
        if (localStorage.wToken) { // 判断当前的token是否存在
            next({
                path: '/home',
                query: { redirect: to.fullPath }
            })
        } else {
            next()
        }
    } else {
        next() // 确保一定要调用 next()
    }
  })

createApp(App).use(router).use(store).mount('#app');
