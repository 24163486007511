import { createRouter,createWebHashHistory } from "vue-router";
import Login from '../views/Login.vue';
import Home from '../views/Home.vue';
import Register from '../views/Register';
import forgetPwd from '../views/forgetPwd';
import FreightMarket from '../views/FreightMarket';
import Center from '../views/Center';
import FreightInquiry from '../views/FreightInquiry';
import AboutUs from '../views/AboutUs';
import Carrier from '../views/Carrier';
import NewGuide from '../views/NewGuide';
import HelpCenter from '../views/HelpCenter';
import InfoDetail from '../views/InfoDetail';
import InfoClassify from '../views/InfoClassify';

const routes = [
    {
        path:'/login',
        component:Login,
        meta:{title:'登录'}
    },
    {
        path:'/register',
        component:Register,
        meta:{title:'注册'}
    },
    {
        path:'/forgetPwd',
        component:forgetPwd,
        meta:{title:'重置密码'}
    },
    {
        path:'/home',
        component:Home,
        meta:{title:'首页'}
    },
    {
        path:'/carrier',
        component:Carrier,
        meta:{title:'我是承运方'}
    },
    {
        path:'/freightMarket',
        component:FreightMarket,
        meta:{title:'货运市场'}
    },
    {
        path:'/freightInquiry',
        component:FreightInquiry,
        meta:{title:'运价查询'}
    },
    {
        path:'/center',
        component:Center,
        meta:{title:'账户中心'}
    },
    {
        path:'/aboutUs',
        component:AboutUs,
        meta:{title:'关于我们'}
    },
    {
        path:'/newGuide',
        component:NewGuide,
        meta:{title:'新手指南'}
    },
    {
        path:'/HelpCenter',
        component:HelpCenter,
        meta:{title:'帮助中心'}
    },
    {
        path:'/infoDetail',
        component:InfoDetail,
        meta:{title:'新闻详情'}
    },
    {
        path:'/infoClassify',
        component:InfoClassify,
        meta:{title:'新闻分类'}
    },
    {
        path:'/',
        redirect:'/login',
        meta:{title:'首页'}
    }
];
const router = createRouter({
    history:createWebHashHistory(),
    routes
});

export default router;
