import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  style: {
    "height": "400px",
    "overflow": "auto"
  }
};
const _hoisted_2 = ["innerHTML"];
const _hoisted_3 = {
  class: "dialog-footer"
};
const _hoisted_4 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_dialog = _resolveComponent("el-dialog");
  const _component_el_checkbox = _resolveComponent("el-checkbox");
  const _component_el_row = _resolveComponent("el-row");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_dialog, {
    title: "协议内容",
    "append-to-body": true,
    modelValue: $data.protocolDialogVisible,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.protocolDialogVisible = $event),
    width: "50%"
  }, {
    footer: _withCtx(() => [_createElementVNode("div", _hoisted_3, [_createVNode(_component_el_button, {
      onClick: _cache[0] || (_cache[0] = $event => $data.protocolDialogVisible = false)
    }, {
      default: _withCtx(() => [_createTextVNode("关闭")]),
      _: 1
    })])]),
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", null, "《 " + _toDisplayString($data.title) + " 》", 1), _createElementVNode("div", {
      innerHTML: $data.protocolContent
    }, null, 8, _hoisted_2)])]),
    _: 1
  }, 8, ["modelValue"]), $data.originAll.length > 0 ? (_openBlock(), _createBlock(_component_el_row, {
    key: 0,
    style: {
      "margin-top": "20px"
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_el_checkbox, {
      modelValue: _ctx.isAgree,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.isAgree = $event),
      onChange: $options.handleChange
    }, {
      default: _withCtx(() => [_createTextVNode("我已阅读并同意")]),
      _: 1
    }, 8, ["modelValue", "onChange"]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.originAll, item => {
      return _openBlock(), _createElementBlock("span", {
        key: item.id,
        style: {
          "color": "#409eff",
          "cursor": "pointer"
        },
        onClick: $event => $options.handleShow(item)
      }, "《" + _toDisplayString(item.title) + "》 ", 9, _hoisted_4);
    }), 128))]),
    _: 1
  })) : _createCommentVNode("", true)]);
}