import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-371b94ba"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "content"
};
const _hoisted_2 = {
  class: "left"
};
const _hoisted_3 = {
  class: "right"
};
const _hoisted_4 = {
  key: 0
};
const _hoisted_5 = {
  key: 1
};
const _hoisted_6 = {
  key: 0,
  class: "map",
  id: "map"
};
const _hoisted_7 = {
  class: "dialog"
};
const _hoisted_8 = {
  class: "hor"
};
const _hoisted_9 = {
  key: 0
};
const _hoisted_10 = {
  key: 1
};
const _hoisted_11 = {
  class: "tc"
};
import { ref, onMounted } from 'vue';
import config from '../../config/config';
import { startOrOverOrderDetail } from "@/api/home";
export default {
  __name: 'WaybillDetail',
  props: {
    waybillDetail: ''
  },
  setup(__props) {
    const props = __props;
    const detail = ref({});
    onMounted(() => {
      detail.value = props.waybillDetail;
      if (props.waybillDetail.transStatus == '已完成') {
        setTimeout(() => {
          //加载地图
          let map = new BMap.Map("map"); // 创建Map实例
          const point = new BMap.Point(116.404, 39.915); // 创建点坐标
          map.centerAndZoom(point, 7); // 初始化地图,设置中心点坐标和地图级别
          map.enableScrollWheelZoom(); //启用地图滚轮放大缩小
          map.enableKeyboard(); //启用键盘上下左右键移动地图
          map.enableDragging(); //启用地图拖拽事件，默认启用(可不写)
          map.enableDoubleClickZoom(); //启用鼠标双击放大，默认启用(可不写)
        }, 1000);
      }
    });
    //上传凭证并启运
    const detailDialogVisible = ref(false);
    const number = ref();
    const uploadType = ref();
    const uploadAndStartFn = type => {
      detailDialogVisible.value = true;
      uploadType.value = type;
    };
    //确定修改头像
    const uploadSuccess = async (response, fileList) => {
      let imgLoc = response.image;
      let userId = localStorage.getItem('wUserId');
      let wRealName = localStorage.getItem('wRealName');
      let param = {
        "id": detail.value.id,
        "imageUrls": imgLoc,
        "number": number.value,
        "opMark": uploadType.value == 'start' ? 1 : 2,
        "userId": userId,
        "updateBy": wRealName
      };
      const res = await startOrOverOrderDetail(param);
      if (!res?.error) {
        ElMessage({
          message: '启运成功',
          type: 'success'
        });
        detailDialogVisible.value = false;
      }
    };
    const uploadRef = ref();
    const confirmFn = () => {
      uploadRef.value.submit();
    };
    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_upload = _resolveComponent("el-upload");
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_dialog = _resolveComponent("el-dialog");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("p", null, "运单号: " + _toDisplayString(detail.value.orderDetailsNum), 1), _createElementVNode("p", null, "承运方: " + _toDisplayString(detail.value.cyfName), 1), _createElementVNode("p", null, "司机: " + _toDisplayString(detail.value.driverName), 1), _createElementVNode("p", null, "运输量: " + _toDisplayString(`${detail.value.actualNum}${detail.value.sizeUnit}`), 1), _createElementVNode("p", null, "运输价: " + _toDisplayString(detail.value.actualTotalprice) + "元", 1)]), _createElementVNode("div", _hoisted_3, [_createElementVNode("p", null, "起点: " + _toDisplayString(`${detail.value.outProvince}${detail.value.outCity}${detail.value.outCounty}${detail.value.outAddress}`), 1), _createElementVNode("p", null, "终点: " + _toDisplayString(`${detail.value.receiveProvince}${detail.value.receiveCity}${detail.value.receiveCounty}${detail.value.receiveAddress}`), 1), _createElementVNode("p", null, "司机电话:" + _toDisplayString(detail.value.driverTel), 1), _createElementVNode("p", null, "车辆: " + _toDisplayString(detail.value.carCode), 1), detail.value.transStatus == '待运输' ? (_openBlock(), _createElementBlock("p", _hoisted_4, [_createVNode(_component_el_button, {
        type: "default",
        class: "uploadBtn",
        onClick: _cache[0] || (_cache[0] = $event => uploadAndStartFn('start'))
      }, {
        default: _withCtx(() => [_createTextVNode("上传凭证并启运")]),
        _: 1
      })])) : _createCommentVNode("", true), detail.value.transStatus == '运输中' ? (_openBlock(), _createElementBlock("p", _hoisted_5, [_createVNode(_component_el_button, {
        type: "default",
        class: "uploadBtn",
        onClick: _cache[1] || (_cache[1] = $event => uploadAndStartFn('end'))
      }, {
        default: _withCtx(() => [_createTextVNode("上传凭证并停运")]),
        _: 1
      })])) : _createCommentVNode("", true)])]), detail.value.transStatus == '已完成' ? (_openBlock(), _createElementBlock("div", _hoisted_6)) : _createCommentVNode("", true), _createVNode(_component_el_dialog, {
        modelValue: detailDialogVisible.value,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => detailDialogVisible.value = $event),
        "destroy-on-close": "",
        title: uploadType.value == 'start' ? '上传凭证并启运' : '上传凭证并停运',
        width: "640"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_7, [_createVNode(_component_el_upload, {
          ref_key: "uploadRef",
          ref: uploadRef,
          class: "upload-demo",
          action: _unref(config).baseUrl + '/v1/image/uploadImages',
          "auto-upload": false,
          multiple: true,
          limit: 1,
          "list-type": "picture",
          "on-success": (response, file, fileList) => {
            uploadSuccess(response, fileList);
          }
        }, {
          trigger: _withCtx(() => [_createVNode(_component_el_button, {
            type: "default",
            class: "choose"
          }, {
            default: _withCtx(() => [_createTextVNode("选择图片")]),
            _: 1
          })]),
          _: 1
        }, 8, ["action", "on-success"]), _createElementVNode("p", _hoisted_8, [uploadType.value == 'start' ? (_openBlock(), _createElementBlock("label", _hoisted_9, "启运吨数：")) : _createCommentVNode("", true), uploadType.value == 'end' ? (_openBlock(), _createElementBlock("label", _hoisted_10, "停运吨数：")) : _createCommentVNode("", true), _createVNode(_component_el_input, {
          class: "conditionInput",
          modelValue: number.value,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => number.value = $event)
        }, null, 8, ["modelValue"])]), _createElementVNode("p", _hoisted_11, [_createVNode(_component_el_button, {
          type: "default",
          class: "confirm",
          onClick: confirmFn
        }, {
          default: _withCtx(() => [_createTextVNode("确定")]),
          _: 1
        })])])]),
        _: 1
      }, 8, ["modelValue", "title"])], 64);
    };
  }
};