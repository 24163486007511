import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3f6b9bee"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "wrapper"
};
const _hoisted_2 = {
  class: "hor"
};
const _hoisted_3 = {
  class: "content"
};
const _hoisted_4 = {
  class: "title"
};
const _hoisted_5 = {
  class: "name"
};
const _hoisted_6 = {
  class: "time"
};
const _hoisted_7 = {
  class: "desc"
};
const _hoisted_8 = ["src"];
const _hoisted_9 = ["innerHTML"];
import { useRouter, useRoute } from 'vue-router';
import { ref, onMounted } from "vue";
import Footer from '../components/Footer';
import { getArticleDetail } from '../api/home';
import config from "@/config/config";
export default {
  __name: 'InfoDetail',
  setup(__props) {
    const route = useRoute();
    const router = useRouter();
    const article = ref({});
    onMounted(() => {
      let articleId = route.query.articleId;
      console.log('id--', articleId);
      if (articleId) {
        query(articleId);
      }
    });
    const query = async articleId => {
      const res = await getArticleDetail(articleId);
      if (res) {
        if (res.article.imgUrl) {
          res.article.imgUrl = config.baseUrl + res.article.imgUrl;
        }
        let arrEntities = {
          'lt': '<',
          'gt': '>',
          'nbsp': ' ',
          'amp': '&',
          'quot': '"'
        };
        const final = res.article.content.replace(/&(lt|gt|nbsp|amp|quot);/ig, (all, t) => {
          return arrEntities[t];
        });
        res.article.content = final;
        article.value = res.article;
      }
    };
    return (_ctx, _cache) => {
      const _component_Header = _resolveComponent("Header");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_Header, {
        activeKey: "home"
      }), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("p", _hoisted_5, _toDisplayString(article.value.title), 1), _createElementVNode("p", _hoisted_6, "发布时间：" + _toDisplayString(article.value.createdAt) + "       来源：" + _toDisplayString(article.value.source), 1)]), _createElementVNode("div", _hoisted_7, [_createElementVNode("img", {
        src: article.value.imgUrl,
        class: "pic"
      }, null, 8, _hoisted_8), _createElementVNode("div", {
        innerHTML: article.value.content
      }, null, 8, _hoisted_9)])])])]), _createVNode(_unref(Footer))], 64);
    };
  }
};