import "core-js/modules/es.array.push.js";
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-04d12622"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "title"
};
const _hoisted_2 = {
  class: "content"
};
const _hoisted_3 = ["onClick"];
import { ref, onMounted } from 'vue';
import addressJson from '../assets/json/address.json';
export default {
  __name: 'Address',
  props: {
    locationStyle: '',
    level: ''
  },
  emits: ['addressFn'],
  setup(__props, {
    emit: __emit
  }) {
    const props = __props;
    const addressData = ref();
    const addressInitData = ref();
    const titleIndex = ref(1);
    const selectedArr = ref([]);
    onMounted(() => {
      console.log(addressJson);
      addressData.value = addressJson;
      addressInitData.value = addressJson;
    });
    //下一步
    const emits = __emit;
    const goNext = (name, data) => {
      if (props.level == '2' && titleIndex.value == 2) {
        //已经点到了最末级
        selectedArr.value.push(name);
        emits('addressFn', selectedArr.value); //回传给父组件
        return;
      }
      if (titleIndex.value == 3) {
        //已经点到了最末级
        selectedArr.value.push(name);
        emits('addressFn', selectedArr.value); //回传给父组件
        return;
      }
      selectedArr.value.push(name);
      titleIndex.value++;
      addressData.value = data;
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", {
        class: "address",
        style: _normalizeStyle(props.locationStyle)
      }, [_createElementVNode("p", _hoisted_1, [_createElementVNode("span", {
        class: _normalizeClass([titleIndex.value == 1 ? 'activeName' : 'name'])
      }, "省份", 2), _createElementVNode("span", {
        class: _normalizeClass([titleIndex.value == 2 ? 'activeName' : 'name'])
      }, "城市", 2), _createElementVNode("span", {
        class: _normalizeClass([titleIndex.value == 3 ? 'activeName' : 'name'])
      }, "区县", 2)]), _createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(addressData.value, item => {
        return _openBlock(), _createElementBlock("p", {
          class: "addressLi",
          onClick: $event => goNext(item.name, item.children)
        }, _toDisplayString(item.name), 9, _hoisted_3);
      }), 256))])], 4);
    };
  }
};